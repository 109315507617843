export default function MainBody () {
	return (
		<>
			<div className='px-1 grid grid-cols-2 gap-2 justify-center'>
			<div className='py-10 text-3xl md:text-5xl font-medium z-10'>New Album</div>
			<div className='py-20 text-3xl md:text-5xl font-medium z-10'>Out Now</div>
			</div>

			<div className='px-3 grid grid-cols-1 gap-10 justify-center'>
			<div className='text-2xl z-10'>11.18.2024</div>
			<div className='text-7xl z-10 md:text-9xl font-glory font-bold'>Here I Am</div>
			</div>

			<div className=' px-1 grid sm:grid-cols-1 md:grid-cols-3 gap-2'>
			<div className='w-full z-10'>
				<iframe style={{borderRadius: '12px'}} src="https://open.spotify.com/embed/album/48ipPovnFb07Q0XBWuUxXQ?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
			</div>
			<div className='w-full z-10'>
				<iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" height="450" style={{width:'100%',maxWidth:'660px',overflow:'hidden',borderRadius:'10px'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/here-i-am-ep/1780245264"></iframe>
			</div>
			<div className='w-full z-10'>
				<iframe id='AmazonMusicEmbedB0DN9QDRFJ' src='https://music.amazon.com/embed/B0DN9QDRFJ/?id=eRxt2TtPxx&marketplaceId=ATVPDKIKX0DER&musicTerritory=US' width='100%' height='352px' frameBorder='0' style={{borderRadius:'20px',maxWidth:''}}></iframe>
			</div>
			</div>
		</>
	)
}