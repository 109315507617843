export default function Contact() {
	return (
		<div className="grid grid-rows-3 gap-1 font-bold">
			<div/>
			<div>
				<p className="text-xl">
					music@heartwoodmusic.net
				</p>
			</div>
			<div>
				<p className="text-xl">
					music@heartwood.rocks
				</p>
			</div>
		</div>
	)
}