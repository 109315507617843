import './App.css';
import img from './assets/red_corn.jpeg'
import Header from './Header'
import { HashRouter, Route, Routes } from 'react-router';
import MainBody from './MainBody';
import About from './About';
import Contact from './Contact';

function App({ body }) {
  return (
    <div className='App h-full w-full overflow-scroll bg-gradient-to-t from-amber-600 to-amber-50'>
      <img
        alt=''
        src={img}
        className='absolute h-full overflow-auto w-full object-cover opacity-35 blur-sm z-1'
      />

      <HashRouter>
        <Header/>
        
        <Routes>
          <Route path='/' element={<MainBody/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/contact' element={<Contact/>}/>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
