export default function About() {
	return (
		<div className="grid grid-cols-1 font-glory">
			<div>
				<br/> 
			</div>
			<div>
				<p className="text-5xl">I moonlight as a local musician in the Crofton, Maryland area when I'm not saving people as a nurse!</p>
			</div>
		</div>
	)
}