import './App.css';
import { Link } from 'react-router';
export default function Header () {
	return (
		<div className='App-header'>
			<div className='text-6xl z-10 font-dafoe col-span-3'>
				Heartwood
			</div>
			<div className='grid grid-cols-4 gap-2'>
				<div className='text-xl'>
					<Link to='/'>Home</Link>
				</div>
				<div className='text-xl'>
					<a href='https://www.facebook.com/heartwoodrocks88'>Events</a>
				</div>
				<div className='text-xl'>
					<Link to='/about'>About</Link>
				</div>
				<div className='text-xl'>
					<Link to='/contact'>Contact</Link>
				</div>
			</div>
		</div>
	)
}